import React, { useState, FC, useRef } from 'react';
import * as Yup from 'yup';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { UserService } from '../../../services/user';
import { useForm, InputWraper, Button, CreateAlert } from '../../../modules';
import { InputText, InputPassword } from '../../../components';
import { AuthWraper } from '../authWraper';
import { getLocaleKey, translate } from '../../../languages';
import { getEnv } from '../../../configs';

// import ReCAPTCHA from "react-google-recaptcha"; //google recaptcha v2
import _ from 'lodash';

export const PageAuthLogin: FC = () => {
    // const [recaptcha, setRecaptcha] = useState('');
    const { executeRecaptcha } = useGoogleReCaptcha() as any;
    const recaptchaRef = useRef<any>(null);

    const [captchaUid, setCaptchaUid] = useState<any>();
    const [dataPuzzleImg, setDataPuzzleImg] = useState<any>();

    const { handleSubmit, getInputProps, isSubmitting } = useForm({
        structure: [
            {
                name: 'userName',
                validate: Yup.string().required(translate('must-be-provided'))
            },
            {
                name: 'password',
                validate: Yup.string().required(translate('must-be-provided'))
            },
            {
                name: "puzzleAnswer",
                validate: dataPuzzleImg != null ? Yup.string().required(translate("must-be-provided")) : null,
            },
        ],
        onSubmit: async (values) => {
            // let captcha = recaptcha;

            // try {
            //     if (!recaptcha) captcha = await executeRecaptcha('login');
            // } catch (error) {
            //     console.log(error);
            // }

            let GoogleRecaptchaToken;

            // try {
            //     recaptchaRef?.current?.reset();
            //     GoogleRecaptchaToken = await recaptchaRef?.current?.executeAsync();
            //     // if (!recaptcha) captcha = await executeRecaptcha("login");
            // } catch (error) {
            //     console.log(error);
            // }

            let payload = {
                userName: values?.userName,
                password: values?.password,
                GoogleRecaptchaToken: GoogleRecaptchaToken,
                captchaUid: captchaUid,
                puzzleAnswer: values?.puzzleAnswer,
            }

            return UserService.login(payload).catch((err) => {
                // setRecaptcha('');

                if (_.isError( _.attempt(JSON.parse, err.message)) === false) {
                    let messageJSON = JSON.parse(err.message);
                    if (messageJSON?.message === "REQUIRED_SOLVE_PUZZLE") {
                        setDataPuzzleImg(messageJSON?.puzzleImg);
                        setCaptchaUid(messageJSON?.captchaUid);
                        return;
                    }
                    if (messageJSON?.message === "SOLVE_PUZZLE_INCORRECTLY") {
                        setDataPuzzleImg(messageJSON?.puzzleImg);
                        setCaptchaUid(messageJSON?.captchaUid);
                        CreateAlert({ message: translate(messageJSON.message), type: "danger" });
                        return;
                    }
                    if (messageJSON?.detail?.includes('timeout-or-duplicate') === true) { //if token Google reCaptcha expired then get new token and call api
                        handleSubmit(); 
                        return;
                    }
                    if (_.isPlainObject(messageJSON)) {
                        CreateAlert({ message: messageJSON.message, type: "danger" });
                        return;
                    }
                }
                
                CreateAlert({ message: err.message, type: 'danger' });
                throw err;
            });
        }
    });

    return (
        <AuthWraper>
            <div className="PageAuth login">
                <img
                    src="/assets/images/maps.png"
                    className="background"
                    alt=""
                />

                <form onSubmit={handleSubmit}>
                    <div className="head">
                        <img
                            className="logo"
                            src="/assets/images/logo-white.png"
                            alt=""
                        />
                        <div className="title">
                            {translate('login-to-your-account')}
                        </div>
                    </div>

                    {/* <GoogleReCaptcha
                        onVerify={(token) => setRecaptcha(token)}
                    /> */}
                    {/* <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={getEnv("GOOGLE_RECAPTCHA_KEY")}
                        size="invisible"
                        hl={getLocaleKey()}
                        badge={'bottomright'}
                    /> */}

                    <InputWraper
                        // label={translate("email-address")}
                        label="Username"
                        inputProps={getInputProps('userName')}
                        component={InputText}
                    />

                    <InputWraper
                        label={translate('password')}
                        inputProps={getInputProps('password')}
                        component={InputPassword}
                    />

                    {dataPuzzleImg && (
                        <>
                            <div
                                style={{ textAlign: "center" }}
                                dangerouslySetInnerHTML={{ __html: dataPuzzleImg }}
                            ></div>
                            <InputWraper
                                label={translate("Puzzle Answer")}
                                inputProps={getInputProps("puzzleAnswer")}
                                component={InputText}
                            />
                        </>
                    )}

                    <Button
                        label={translate('login')}
                        type="submit"
                        isLoading={isSubmitting}
                    />
                </form>
            </div>
        </AuthWraper>
    );
};
